import React from "react";
import qore from "../../context/qoreContext";
import {
  Container,
  Breadcrumb,
  BreadcrumbItem,
  Grid,
  GridItem,
  Text,
  Box,
  Flex,
  Spacer,
  Image,
  IconButton,
  useToast
} from "@chakra-ui/react";
import { ChevronRightIcon } from "@chakra-ui/icons";
import { FaWhatsapp, FaTwitter, FaFacebook, FaShareAlt } from "react-icons/fa";
import { WhatsappShareButton, TwitterShareButton, FacebookShareButton } from "react-share";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import DOMPurify from "dompurify";
import Skeleton from "react-loading-skeleton";
import Slider from "react-slick";
import styled from "styled-components";

import "../../css/banner.css";

const ArticleContainer = styled.div`
  font-family: "Avenir";
  font-size: "16px";

  p {
    margin-bottom: 16px;
  }
`;

const Article = ({ slug }) => {
  const { data: article, status } = qore.view("publishedTips").useListRow({
    slug
  });

  const createMarkup = html => {
    return {
      __html: DOMPurify.sanitize(html)
    };
  };

  const { data: listArticle, status: statusList } = qore
    .view("publishedTips")
    .useListRow({ "$by.index": "asc", limit: 5 });
  const skeletonCount = [1, 2, 3, 4];

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3.5,
    slidesToScroll: 3,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 3.5,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2.5,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1.5,
          slidesToScroll: 1
        }
      }
    ]
  };

  const toast = useToast();

  const handleShareLink = () => {
    navigator.clipboard.writeText(window.location.href);
    toast({
      title: "link berhasil disalin",
      status: "error",
      isClosable: true,
      position: "top"
    });
  };

  const tagList = ["OhMyGlam", "OMGNeverFade", "OhMyGlow", "BeautyNeverFade"];

  return (
    <>
      <Container maxW={{ lg: "container.md", xl: "container.xl" }} pt={10} pb={40}>
        <Breadcrumb
          spacing="8px"
          mb={{ base: 8, md: 10 }}
          separator={<ChevronRightIcon color="black" />}>
          <BreadcrumbItem>
            <Link to="/">Home</Link>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <Link to="/beauty-tips">Beauty Tips</Link>
          </BreadcrumbItem>
        </Breadcrumb>
        <Grid w="100%" templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(5, 1fr)" }} gap={1}>
          <GridItem mt={{ base: 4, md: 0 }} colSpan={{ base: 5, md: 1 }} order={{ base: 2, md: 1 }}>
            <Grid
              w="100%"
              templateColumns={{ base: "repeat(5, 1fr)", md: "repeat(1, 1fr)" }}
              textAlign="start">
              <GridItem colSpan={{ base: 1, md: 5 }}>
                <Text>Share</Text>
              </GridItem>
              <GridItem colSpan={{ base: 1, md: 5 }}>
                <WhatsappShareButton
                  url={`${process.env.GATSBY_OMG_URL}/beauty-tips/article/${slug}`}
                  title={article[0]?.title}>
                  <IconButton
                    rounded="full"
                    bg="transparent"
                    aria-label="Copy Link"
                    _focus={{ boxShadow: "none" }}
                    icon={<FaWhatsapp />}
                  />
                </WhatsappShareButton>
              </GridItem>
              <GridItem colSpan={{ base: 1, md: 5 }}>
                <TwitterShareButton
                  url={`${process.env.GATSBY_OMG_URL}/beauty-tips/article/${slug}`}
                  title={article[0]?.title}
                  hashtags={tagList}
                  via={"itsomgbeauty"}>
                  <IconButton
                    rounded="full"
                    bg="transparent"
                    aria-label="Copy Link"
                    _focus={{ boxShadow: "none" }}
                    icon={<FaTwitter />}
                  />
                </TwitterShareButton>
              </GridItem>
              <GridItem colSpan={{ base: 1, md: 5 }}>
                <FacebookShareButton
                  url={`${process.env.GATSBY_OMG_URL}/beauty-tips/article/${slug}`}>
                  <IconButton
                    rounded="full"
                    bg="transparent"
                    aria-label="Copy Link"
                    _focus={{ boxShadow: "none" }}
                    icon={<FaFacebook />}
                  />
                </FacebookShareButton>
              </GridItem>
              <GridItem colSpan={{ base: 1, md: 5 }}>
                <IconButton
                  rounded="full"
                  bg="transparent"
                  _focus={{ boxShadow: "none" }}
                  aria-label="Copy Link"
                  icon={<FaShareAlt />}
                  onClick={handleShareLink}
                />
              </GridItem>
            </Grid>
          </GridItem>
          <GridItem colSpan={{ base: 5, md: 4 }} order={{ base: 1, md: 2 }}>
            <Box display={{ base: "none", md: "block" }}>
              <Link to="/skincare">
                <StaticImage
                  layout="fullWidth"
                  objectFit="contain"
                  src="../../images/banner/beauty-tips-skincare.png"
                  alt="beauty-tips-bg"
                  loading="lazy"
                />
              </Link>
            </Box>
            <Box display={{ base: "block", md: "none" }}>
              <StaticImage
                layout="fullWidth"
                objectFit="contain"
                src="../../images/banner/beauty-tips-mobile.png"
                alt="beauty-tips-bg"
                loading="lazy"
              />
            </Box>
          </GridItem>
        </Grid>
        <Flex mt={{ base: 4, lg: 10 }}>
          <Spacer display={{ base: "none", md: "block" }} />
          <Box w={{ base: "100%", md: "80%" }}>
            {status !== "loading" && article.length ? (
              <>
                <Text fontWeight="bold" fontSize="21px" mb={4}>
                  {article[0]?.title}
                </Text>
                <ArticleContainer dangerouslySetInnerHTML={createMarkup(article[0]?.details)} />
              </>
            ) : (
              <>
                <Skeleton height={21} width={"50%"} />
                <br />
                <Skeleton count={12} />
              </>
            )}
            <Text fontWeight="bold" fontSize="21px" my={8}>
              Beauty Tips Lainnya
            </Text>
            <Box py={10} overflow="hidden">
              {statusList === "success" && listArticle.length ? (
                <Slider {...settings}>
                  {listArticle.length &&
                    listArticle
                      .filter(article => article.slug !== slug)
                      .map((content, idx) => (
                        <Box px={2} key={idx}>
                          <Link to={`/beauty-tips/article/${content.slug}`}>
                            <Image
                              src={content.imgWebp || content.img}
                              fallbackSrc={content.img}
                              alt={content?.description || `content-${idx}`}
                              objectFit="cover"
                              loading="lazy"
                            />
                          </Link>
                        </Box>
                      ))}
                </Slider>
              ) : (
                <Grid
                  templateColumns={{ base: "repeat(3, 1fr)", md: "repeat(4, 1fr)" }}
                  gap={6}
                  overflow="hidden">
                  {skeletonCount.map(skeleton => (
                    <GridItem
                      key={skeleton}
                      h={{ base: "140px", md: "200px" }}
                      w={{ base: "140px", md: "200px" }}
                      display={skeleton === 4 ? { base: "none", md: "block" } : { base: "block" }}>
                      <Skeleton height="100%" width="100%" />
                    </GridItem>
                  ))}
                </Grid>
              )}
            </Box>
          </Box>
        </Flex>
      </Container>
    </>
  );
};

export default Article;

import React from "react";
import Layout from "../../components/layout";
import Seo from "../../components/seo";
import ArticleComponent from "../../components/beauty-tips/Article";

const Article = props => {
  const slug = props.params["*"];
  return (
    <Layout>
      <Seo title={slug || "Beauty Tips OMG"} />
      <ArticleComponent slug={slug} />
    </Layout>
  );
};

export default Article;
